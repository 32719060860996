.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.wrap-drop-down{
  position: relative;
  width: 231px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.Dropdown-arrow-wrapper{
  display: flex;
  align-items: center;
}
/*.wrap-drop-down .Dropdown-control:after{*/
/*  content: '';*/
/*  display: block;*/
/*  position: absolute;*/
/*  right: 15px;*/
/*  top: 15px;*/
/*}*/
/*.wrap-drop-down.is-open .Dropdown-arrow-wrapper .Dropdown-arrow{*/
/*  width: 0;*/
/*  height: 0;*/
/*  border-left: 5px solid transparent;*/
/*  border-right: 5px solid transparent;*/
/*  border-top: 10px solid #666;*/
/*}*/
.wrap-drop-down .Dropdown-control{
  width: 100%;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px !important;
  padding-right: 1px !important;
  border: 1px solid #c4c4c4;
}
.Dropdown-arrow-wrapper svg {
  fill: rgba(0, 0, 0, 0.54);
}
.Dropdown-arrow-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color .3s;
}
.Dropdown-arrow-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.wrap-drop-down .Dropdown-control:hover{
  border: 1px solid #666;
}

.wrap-drop-down .Dropdown-placeholder, .wrap-datepicker .MuiInputBase-input{
  padding: 8px 14px;
}
.wrap-datepicker .MuiInputBase-input{
  padding: 9.5px 14px;
}

.wrap-drop-down.is-open .Dropdown-menu{
  opacity: 1;
  transform: none;
  transition: opacity 320ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 213ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 100%;
  position: absolute;
  z-index: 99;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /*transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;*/
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  transform-origin: top center;
  outline: 0;

}
/*.wrap-drop-down.is-open .Dropdown-menu:hover{*/
/*  border: 1px solid #666;*/
/*}*/
.wrap-drop-down.is-open .Dropdown-menu .Dropdown-option{
  padding-top: 12px;
  padding-bottom: 12px;
}
.wrap-drop-down.is-open .Dropdown-menu .Dropdown-option:hover{
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.wrap-container{
  align-items: center;
}
.Mui-selected.tab-btn{
  color: #FF9700 !important;
}
.wrap-tab-btn .MuiTabs-indicator {
  background: #FF9700 !important;
}
.table-header tr th{
  background: rgb(255 218 173);
}
/*.table-header{*/
/*  background: rgba(255, 151, 0, 0.37);*/
/*}*/


.failure h3{
  padding: 75px;
  box-shadow: 0 1px 33px -5px #bababa87;
  background: #f3dfdf59;
  border-radius: 10px;
  color: #992121;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
